.spinner {
  margin: 24px auto;
  border-radius: 50%;
  width: 64px;
  height: 64px;
  border: 6px solid gray;
  border-top: 6px solid gray;
  animation: rotating 1.2s infinite cubic-bezier(0.785, 0.135, 0.15, 0.86);
}

.moxie-purple-primary {
  background: #4f0751;
}

.yellow-primary-background {
  background: #f2a83f;
}

.default-primary-background {
  background: #5B77E1;
}

.release-primary-background {
  background: #1232D7;
}

.finguard-primary-background {
  background-color: #2D9DA3;
}


.moego-primary-background {
  background: rgb(249, 107, 24)
}

.carry-primary-background {
  background: #151515;
}

.mask-white {
  filter: brightness(0) invert(1);
}

.mask-none {}

.mask-grey {
  filter: brightness(0) opacity(0.5);
}

button {
  border-radius: 100px;
  letter-spacing: 0.6px;
  border: 0;
  height: 48px;
  min-width: 155px;
  padding-left: 25px;
  padding-right: 25px;
  margin-top: 20;
  margin-left: 20;
  color: white;
  font-size: 15px;
  transition: all 0.2s ease-out;
  font-family: 'Poppins', sans-serif;
  font-weight: 700;
}

button[disabled] {
  opacity: 0.2;
}

button:hover:enabled {
  opacity: 0.5;
  cursor: pointer;
}

@keyframes rotating {
  100% {
    transform: rotate(360deg);
  }
}

.clickable-text {
  cursor: pointer;
  text-decoration: underline;

}

.page-container {
  margin: 24px;
}

.outer-container {
  margin-left: auto;
  margin-right: auto;

  max-width: 100%;
  //padding-left: 1.5rem;
  //padding-right: 1.5rem;
}

.inner-container {

  margin-left: auto;
  margin-right: auto;
  max-width: 48rem;
  margin-bottom: 6rem;
}

.header {
  background-color: white;
  width: 100%;
  flex-direction: row;
  padding-top: 1.5rem;
  padding-bottom: 1.5rem;
  align-items: center;
  justify-content: space-between;
  display: flex;
  max-width: 80rem;
  margin-left: auto;
  margin-right: auto;
}

.title {
  font-size: 3.75rem; /* 60px */
  line-height: 4rem;

  font-weight: 400;

  letter-spacing: -0.025em;
  color: rgb(17 24 39);
  font-family: 'DM Serif Text', serif;
  margin-top: 2rem;
  margin-bottom: 2rem;
}

.half-width-section {
  max-width: 36rem;
}

.header-logo {
  width: 80px;
  height: 20px
}

.moego-logo {
  width: 134px;
  height: 36px;
}

.release-logo {
  width: 96px;
  height: 30px;
}

.finguard-logo {
  width: 32px;
  height: 32px;
}

.carry-logo {
  width: 108px;
  height: 30px;
}


.header-logo-wide {
  width: 80px;
  height: 40px
}

.subtitle-text {
  margin-top: 1.5rem;
  font-size: 1.125rem; /* 18px */
  line-height: 1.75rem; /* 28px */

  color: rgb(75 85 99);
  margin-bottom: 1.5rem;
}

.italic {
  font-style: italic;
}

.cta-text {
  font-size: 1.75rem; /* 20px */
  line-height: 1.95rem; /* 28px */
  font-weight: 600;

  color: rgb(17 24 39);
}

.centering {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.icon {
  width: 32px;
  height: 32px;
}

.green-secondary-background {
  background-color: #2c664d
}

.moego-secondary-background {
  background-color: #fef0e8;
}

.default-secondary-background {
  background-color: #5B77E1;
}

.release-secondary-background {
  background-color: #f3f4f6;
}

.slate-background {
  background-color: #204c47;
}

.finguard-secondary-background {
  background-color: #2D9DA3;
}

.carry-secondary-background {
  background-color: #c8ceff;
}

.horizontal-section {
  width: 100%;
  padding-top: 50px;
  padding-bottom: 50px;
  margin-left: auto;
  margin-right: auto;
}

.default-horizontal-section-text > * p, .default-horizontal-section-text > p {
  color: white;
}

.moego-secondary-section-text > * p, .moego-secondary-section-text > p {
  color: black;
}


.release-secondary-section-text > * p, .release-secondary-section-text > p {
  color: black;
}

.carry-secondary-section-text > * p, .carry-secondary-section-text > p {
  color: black;
}

.horizontal-section-inner {
  max-width: 270px;
  margin-left: auto;
  margin-right: auto;
}

.horizontal-title-section {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 10px;
}

.horizontal-title {
  font-size: 1.25rem;
  font-weight: 400;
  margin: 0px;
}

.horizontal-section-text {
  font-size: 15px;
  line-height: 25px;
}

.slate-section-inner {
  max-width: 1250px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  justify-items: start;
  gap: 212px;
  margin-left: auto;
  margin-right: auto;
}

.slate-title {
  font-size: 2.5rem;
  line-height: 2.75rem;
  font-weight: 400;
  font-family: 'DM Serif Text', serif;
  margin-left: auto;
  margin-right: auto;
  text-align: center;
  margin-top: 0px;
  margin-bottom: 40px;
}

.escape-link {
  font-family: 'Poppins', sans-serif;
  font-size: .9rem;
  font-weight: 500;
  font-style: italic;
}

.account-list > * li {

}

.account-list-item {
  display: flex;
  justify-content: space-between;
  column-gap: 1.5rem; /* 24px */
  padding-top: 1.25rem; /* 20px */
  padding-bottom: 1.25rem; /* 20px */

  border-top-width: 1px;
  border-bottom-width: 0px;
  border-left-width: 0px;
  border-right-width: 0px;
  border-color: rgb(243 244 246);
  border-style: solid
}

.account-list-left {
  display: flex;
  flex-direction: row;
  min-width: none;
  column-gap: 1rem;
}

.account-left-text {
  min-width: none;
  flex: 1 1 auto;
}

.account-list-right {
  display: flex;
  flex-direction: column;
  align-items: end;
  flex-shrink: 0;
}

.account-name {
  font-size: 0.875rem; /* 14px */
  line-height: 1.25rem; /* 20px */
  font-weight: 600;
  color: rgb(17 24 39);
}

.account-full-name {
  margin-top: 0.25rem;
  color: rgb(107 114 128);
  font-size: 0.75rem; /* 12px */
  line-height: 1rem; /* 16px */
  margin-bottom: 0;

}

.account-type {
  color: rgb(17 24 39);
  font-size: 0.875rem; /* 14px */
  line-height: 1.25rem; /* 24px */
}

.connected-outer {
  display: flex;
  align-items: center;
  column-gap: .375rem;
}

.connected-inner {
  flex: none;
  border-radius: 9999px;
  background-color: rgba(16, 185, 129, 0.2);
  padding: 0.25rem;
}

.connected-dot {
  height: 0.375rem; /* 6px */
  width: 0.375rem; /* 6px */
  border-radius: 9999px;
  background-color: rgb(16, 185, 129);
}

.broken-inner {
  flex: none;
  border-radius: 9999px;
  background-color: rgba(251, 153, 160, 0.2);
  padding: 0.25rem;
}

.broken-dot {
  height: 0.375rem; /* 6px */
  width: 0.375rem; /* 6px */
  border-radius: 9999px;
  background-color: rgb(240, 90, 103);
}

.connected-text {
  line-height: 1rem; /* 16px */
  font-size: 0.75rem; /* 12px */
  color: rgb(107 114 128);
  margin:0
}